import React, { useEffect } from "react";
import axios from "../../plugins/axios";
import { useLocation, useHistory } from "react-router-dom";
import Waiting from "../shared/Waiting";

const apiUrl = process.env.REACT_APP_FORM_API_URL;

const RequestQuote = () => {
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const generateQuote = async () => {
      var response = await axios.get(`${apiUrl}/generatequote?${params}`);
      console.log(response.data);
      history.push(`/quote/${response.data}`);
    };

    generateQuote();
  }, [location, history]);

  return <Waiting message="Building your quote ..." />;
};

export default RequestQuote;
