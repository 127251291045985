import React, { useState, useEffect, useMemo } from "react";
import axios from "../plugins/axios";
import worknestBackground from "../images/worknest-background.png";
import safetyNestLogo from "../images/SafetyNest_Purple_Positive_RGB.svg";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { format } from "date-fns";

const apiUrl = process.env.REACT_APP_FORM_API_URL;

const Hiscox = (props) => {
    const { answer, identifier } = props.match.params;

    const [info, setInfo] = useState({ message: "..." });
    
    return (
        <Grid container style={{ height: "100vh" }} justifyContent="space-between">
            <Hidden xsDown>
                <Grid item sm={6}>
                    <img
                        src={worknestBackground}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        alt="Legal"
                    />
                </Grid>
            </Hidden>

            <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                direction="column"
                justifyContent="space-between"
                style={{ padding: 10 }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 600,
                        minWidth: 200,
                    }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        style={{ padding: 20, minHeight: "160px" }}
                    >
                        <img src={safetyNestLogo} alt="logo" width={300} />
                    </Grid>
                    <div style={{ marginTop: "80px" }}>test</div>
                </div>
            </Grid>
        </Grid>
    );
};

export default Hiscox;
