import React from 'react';
import { Container } from 'reactstrap';

const Layout = props => (
    <div style={{ height: "100vh" }}>
        <Container fluid={true} className="fullHeight">
            {props.children}
        </Container>
    </div>
);

export default Layout;
