import React, { useEffect } from "react";
import { connect } from "react-redux";
import QuoteChoices from "./QuoteChoices";
import ProductSection from "./ProductSection";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/quoteReducer";
import * as types from "../../store/constants";
import QuoteSummary from "./QuoteSummary";
import QuoteStageButtons from "./QuoteStageButtons";
import Checkout from "./Checkout";
import { useTheme } from "@material-ui/core/styles";
import Waiting from "../shared/Waiting"
import ConfirmSaved from "./ConfirmSaved";
import ConfirmComplete from "./ConfirmComplete";
import HaveQuestion from "./HaveQuestion";
import workNestStyles from "../../theme/worknest";
import { useHistory, useLocation } from "react-router-dom";

import {
    Grid,
    Typography,
    Container,
    Box,
    Paper,
    useMediaQuery
} from "@material-ui/core";

const Quote = ({ getQuote, quoteState, setProducts }) => {

    const location = useLocation();
    const history = useHistory();

    const classes = workNestStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    const setSelected = (product, selected) => {
        console.log("set", product, selected);
        setProducts(quoteState.products.map(p => p.productCode === product.productCode ? { ...p, selected: selected === "yes" } : p));
    }

    useEffect(() => {

    }, [location]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (location.search) {
            console.log('p', params, location.search);
            getQuote(params);
            //history.replace(`/quote`);
        }

    }, [getQuote, location.search, history]);

    if (quoteState.isOrdering)
        return <Waiting message="Please wait whilst we complete your order ..." />;

    if (quoteState.isOrdered)
        return <Waiting message={`Already ordered: ${quoteState.name}`} still />;

    if (quoteState.isLoading)
        return <Waiting message="Loading quote ..." />;

    if (quoteState.isInvalid)
        return <Waiting message="Invalid link" still />;

    if (quoteState.isSaving)
        return <Waiting message="Saving quote ..." />;

    if (quoteState.isSaved)
        return <ConfirmSaved />;

    if (!quoteState.quoteOptions)
        return null;

    return (
        <Container style={{ padding: 0 }}>
            <Grid container spacing={isMobile ? 0 : 4} className={classes.grid} direction={isMobile ? "column-reverse" : "row"}>
                <Grid item xs={12} sm={7}>
                    <Paper className={classes.panel}>
                        {
                            isMobile &&
                            <QuoteChoices />
                        }
                        {
                            quoteState.stage === "quote" &&
                            <Box display="flex" flexDirection="column">
                                {
                                    quoteState.salesInfo &&
                                    <Box>
                                        <div dangerouslySetInnerHTML={{ __html: quoteState.salesInfo }}></div>
                                    </Box>
                                }
                                {/*<Box mb={1}><Typography variant="h4" mb={3}><strong>Your Quote</strong></Typography></Box>*/}
                                <Box mb={2}><Typography><strong>{quoteState.name}</strong></Typography></Box>
                                {
                                    quoteState.products.map(product =>
                                        <ProductSection
                                            key={product.productCode}
                                            product={product}
                                            setSelected={(e) => setSelected(product, e.target.value)}
                                        />
                                    )
                                }
                            </Box>
                        }
                        {
                            quoteState.stage === "checkout" &&
                            <Checkout />
                        }
                        {
                            quoteState.stage === "complete" &&
                            <ConfirmComplete />
                        }
                        {
                            isMobile &&
                            < QuoteStageButtons />
                        }
                    </Paper>
                    {
                        isMobile &&
                        <HaveQuestion />
                    }
                </Grid>
                <Grid item xs={12} sm={5}>
                    {
                        isMobile
                            ? <QuoteSummary />
                            : (
                                <React.Fragment>
                                    <Paper className={classes.panel}>
                                        <Box display="flex" flexDirection="column" style={{ height: "100%" }} gridGap="10px">
                                            {quoteState.stage === "quote" && <QuoteChoices />}
                                            <QuoteSummary />
                                            <QuoteStageButtons />
                                        </Box>
                                    </Paper>
                                    <HaveQuestion />
                                </React.Fragment>
                            )
                    }
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = state => ({ quoteState: state.quoteReducer });

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setProducts: products => dispatch({ type: types.SET_QUOTE_PRODUCTS, products })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quote);

