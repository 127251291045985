import React, { useState, useEffect, useMemo } from "react";
import axios from "../plugins/axios";
import worknestBackground from "../images/worknest-background.png";
import safetyNestLogo from "../images/SafetyNest_Purple_Positive_RGB.svg";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { format } from "date-fns";

const apiUrl = process.env.REACT_APP_FORM_API_URL;

const VisitResponse = (props) => {
    const { answer, identifier } = props.match.params;

    const [info, setInfo] = useState({ message: "..." });

    useEffect(() => {
        const submitResponse = async () => {
            if (identifier) {
                try {
                    var response = await axios.get(
                        `${apiUrl}/submitvisitresponse/${identifier}/${answer === "yes" ? true : false
                        }`
                    );
                    setInfo(response.data);
                } catch (err) {
                    console.log(err);
                    setInfo({ message: err.message });
                }
            }
        };

        if (answer === "yes" || answer === "no") submitResponse();
        else setInfo({ message: "Invalid response" });
    }, [identifier, answer]);

    console.log("response", identifier, answer);

    const displayInfo = useMemo(() => {
        if (info.message)
            return (
                <Typography align="center" variant="h5">
                    {info.message}
                </Typography>
            );

        if (info.status === "OLD LINK")
            return (
                <div>
                    <p>
                        The visit is no longer available. Please get in touch with <a href="mailto:diarymanagement@elliswhittam.com">Diary Management</a>, or you consultant to arrange a visit.
                    </p>
                </div>
            );

        if (info.status === "NOT FOUND") {
        }

        if (info.status === "OK") {
            if (answer === "yes") {
                return (
                    <div>
                        <p>
                            Success! Thank you for confirm my visit on {format(new Date(info.visitDate), "dd/MM/yyyy")} you will get an Outlook calendar invite shortly.
                        </p>
                        <p>If you have any question in the interim, please get in touch.</p>
                        <p>Thanks</p>
                        <p>{info.consultantName}</p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <p>
                            Sorry the date wasn't right for you. Please get in touch with{" "}
                            <a href="mailto:diarymanagement@elliswhittam.com">
                                Diary Management
                            </a>{" "}
                            or myself so we can look at some alternatives dates.
                        </p>
                        <p>Thanks</p>
                        <p>{info.consultantName}</p>
                    </div>
                );
            }
        }
    }, [info, answer]);

    return (
        <Grid container style={{ height: "100vh" }} justifyContent="space-between">
            <Hidden xsDown>
                <Grid item sm={6}>
                    <img
                        src={worknestBackground}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        alt="Legal"
                    />
                </Grid>
            </Hidden>

            <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                direction="column"
                justifyContent="space-between"
                style={{ padding: 10 }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 600,
                        minWidth: 200,
                    }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        style={{ padding: 20, minHeight: "160px" }}
                    >
                        <img src={safetyNestLogo} alt="logo" width={300} />
                    </Grid>
                    <div style={{ marginTop: "80px" }}>{displayInfo}</div>
                </div>
            </Grid>
        </Grid>
    );
};

export default VisitResponse;
