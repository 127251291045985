import React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import worknestLogo from '../images/worknest-small.svg'
import { useTheme } from '@material-ui/core/styles';

import {
    Grid,
    Box,
    AppBar as MuiAppBar,
    Toolbar,
    makeStyles,
    useMediaQuery
} from "@material-ui/core";

import { Link } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: '#150850'
    },
    gridItem: {
        display: 'flex'
    },
    logo: {
        height: '50px',
        width: '50px'
    },
    title: {
        color: 'white', 
        textDecoration: 'none', 
        fontSize: '1.25rem', 
        fontWeight: '500', 
        marginLeft: '10px'
    },
    button: {
        borderRadius: '100%', 
        width: '35px', 
        height: '35px', 
        minWidth: '0px', 
        background: '#eee'
    },
    buttonIcon: {
        height: '15px', 
        width: '15px'
    }
}))

const Header = () => {        
    const classes = useStyles();   
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
   
    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position="sticky">
                <Toolbar>
                    <Grid container alignItems="center" style={isMobile ? { paddingTop: '15px', paddingBottom: '5px'} : {}}>   
                        <Grid container className={classes.gridItem} alignItems='center' item xs={11} justifyContent={ isMobile ? "center" : "flex-start"} >
                            <img alt='logo' src={worknestLogo} className={ classes.logo } />                     
                            <Box display={{ xs: 'none', sm: 'block' }} ><Link className={classes.title} to="#">WorkNest</Link></Box>
                        </Grid>                                    
                    </Grid>
                </Toolbar>
            </AppBar>            
        </React.Fragment>
    );
}

const mapStateToProps = store => ({ user: store.userReducer });

export default connect(mapStateToProps)(withTheme(Header));
