import React, { useState, useMemo } from "react";
import axios from "../plugins/axios";
import Waiting from "./shared/Waiting"
import {
    Typography,
    Box,
    makeStyles,
    Container,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
} from "@material-ui/core";


const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: 'white'
    },
    gridItem: {
        display: 'flex'
    },
    logo: {
        height: '50px',
        width: '50px'
    },
    title: {
        color: 'white',
        textDecoration: 'none',
        fontSize: '1.25rem',
        fontWeight: '500',
        marginLeft: '10px'
    },
    button: {
        borderRadius: '100%',
        width: '35px',
        height: '35px',
        minWidth: '0px',
        background: '#eee'
    },
    buttonIcon: {
        height: '15px',
        width: '15px'
    }
}))

const apiUrl = process.env.REACT_APP_FORM_API_URL;

const Registration = (props) => {
    const classes = useStyles();

    const [submitting, setSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);

    const [formInfo, setFormInfo] = useState({});

    const validEmail = email => {
        var valid = /^[^.](?!.*\.\.)([\w+'.-]+)(?<!\.)@([\w.-]+)[^.]$/.test(email);
        return valid;
    }
 

    const setField = e => {

        let updated = {
            ...formInfo,
            [e.target.name]: e.target.value
        };

        setFormInfo(updated);
    }

    const setCheckboxField = e => {

        let updated = {
            ...formInfo,
            [e.target.name]: e.target.checked
        };

        setFormInfo(updated);
    }


    const isValid = useMemo(() => {
        const isValid = formInfo.agree
            && formInfo.firstName?.trim()
            && formInfo.lastName?.trim()
            && formInfo.companyName?.trim()
            && formInfo.policyNumber?.trim()
            && formInfo.broker?.trim()
            && formInfo.addressLine1?.trim()
            && formInfo.city?.trim()
            && formInfo.postCode?.trim()
            && validEmail(formInfo.email);

        return isValid;

    }, [formInfo]);

    const submit = async () => { 

        setSubmitting(true);

        try {
            await axios.post(`${apiUrl}/registration/submit`, formInfo);
            setComplete(true);
        }
        catch (err) {
            setSubmitting(false);
        }       
    };

    if (complete)
        return <Waiting message="Thankyou.  Some one will contact you shortly." still/>;

    if (submitting)
        return <Waiting message="Submitting form ..." />
            

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" gridGap="8px">
                <Typography variant="h1" align="center">REGISTRATION FORM</Typography>
                <Typography><strong>Register your details below and we wil contact you about access to the HR Knowledge Base.</strong> As part of your industry membership organisation you can get access to the HR Knowledge Base, created by Business HR Solutions, which is the go-to resource for thousands of business owners and managers across the UK.</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            name="firstName"
                            size="small"
                            value={formInfo.firstName || ""}
                            onChange={setField}
                            error={formInfo.agree && !formInfo.firstName?.trim()}
                            inputProps={{ maxLength: 40 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            name="lastName"
                            size="small"
                            value={formInfo.lastName || ""}
                            onChange={setField}
                            error={formInfo.agree && !formInfo.lastName?.trim()}
                            inputProps={{ maxLength: 80 }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    value={formInfo.email || ""}
                    onChange={setField}
                    error={formInfo.agree && !validEmail(formInfo.email)}
                    inputProps={{ maxLength: 80 }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={formInfo.phone || ""}
                            onChange={setField}
                            name="phone"
                            label="Phone"
                            variant="outlined"
                            inputProps={{ maxLength: 40 }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={formInfo.mobile || ""}
                            onChange={setField}
                            name="mobile"
                            label="Mobile"
                            inputProps={{ maxLength: 40 }}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Policy/Membership Number"
                            variant="outlined"
                            name="policyNumber"
                            size="small"
                            value={formInfo.policyNumber || ""}
                            onChange={setField}
                            error={formInfo.agree && !formInfo.policyNumber?.trim()}
                            inputProps={{ maxLength: 40 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Insurance Broker / Membership Body"
                            variant="outlined"
                            name="broker"
                            size="small"
                            value={formInfo.broker || ""}
                            onChange={setField}
                            error={formInfo.agree && !formInfo.broker?.trim()}
                            inputProps={{ maxLength: 80 }}
                        />
                    </Grid>
                </Grid>                
                <TextField
                    value={formInfo.companyName || ""}
                    onChange={setField}
                    name="companyName"
                    label="Company Name"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    error={formInfo.agree && !formInfo.addressLine1?.trim()}
                />
                <TextField
                    value={formInfo.addressLine1 || ""}
                    onChange={setField}
                    name="addressLine1"
                    label="Address 1"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    error={formInfo.agree && !formInfo.addressLine1?.trim()}
                />
                <TextField
                    value={formInfo.addressLine2 || ""}
                    label="Address 2"
                    name="addressLine2"
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={formInfo.addressLine3 || ""}
                    label="Address 3"
                    name="addressLine3"
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    variant="outlined"
                    size="small"
                />               
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={formInfo.city || ""}
                            onChange={setField}
                            name="city"
                            label="City"
                            variant="outlined"
                            error={formInfo.agree && !formInfo.city?.trim()}
                            inputProps={{ maxLength: 40 }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={formInfo.postCode || ""}
                            onChange={setField}
                            name="postCode"
                            label="Postcode"
                            inputProps={{ maxLength: 20 }}
                            variant="outlined"
                            size="small"
                            error={formInfo.agree && !formInfo.postCode?.trim()}
                        />
                    </Grid>
                </Grid>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formInfo.agree || false}
                            onChange={setCheckboxField}
                            name="agree"
                            color="primary"
                        />
                    }
                    label={<Typography>By completing this form you are agreeing to our <a target="_blank" href="https://worknest.com/privacy-policy/" rel="noreferrer">Privacy Policy</a></Typography>}
                />
                {isValid && <Button color="primary" variant="contained" onClick={submit}>Submit</Button>}
            </Box>
        </Container>       
    );
};

export default Registration;
