import React from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTimes } from "@fortawesome/free-solid-svg-icons";
//import { Button } from 'react-bootstrap';

const formatAddress = site => {
    let address = [];
    site.accountName && address.push(site.accountName);
    site.street && address.push(site.street);
    site.city && address.push(site.city);
    site.county && address.push(site.county);
    site.postCode && address.push(site.postCode);

    return address.join(", ");
}

const AddressBar = ({ site }) => {
    return (
        <div style={{ borderRadius: '5px', backgroundColor: 'rgb(233,236,239)', textAlign: 'center', display: 'flex', marginBottom: '12px', paddingLeft: '6px' }}>
            {formatAddress(site)}            
        </div>
    );
}

export default AddressBar;