import Home from '../components/Home';
import InsuranceDeclarationDetails from '../components/insurance/InsuranceDeclarationDetails';
import VisitResponse from '../components/VisitResponse';
import Hiscox from '../components/Hiscox';
import Quote from '../components/marketplace/Quote';
import RequestQuote from '../components/marketplace/RequestQuote';
import Registration from '../components/Registration';

const Blank = () => "Blank";

const homeRoutes = {
    id: "Home",
    path: "/",
    component: Home
}

export const quoteRoutes = [{
    path: "/quote",
    name: "Quote",
    component: Quote
},
{
    path: "/registration",
    name: "Registration",
    component: Registration
}];

// This route is not visible in the sidebar
const privateRoutes = {
    id: "Private",
    path: "/private",
    component: Blank,
    children: [
        {
            path: "/insurance-declaration/:identifier?",
            name: "InsuranceDeclarationDetails",
            component: InsuranceDeclarationDetails
        },
        {
            path: "/visitresponse/:identifier/:answer",
            name: "VisitResponse",
            component: VisitResponse
        },
        {
            path: "/requestquote",
            name: "RequestQuote",
            component: RequestQuote
        },
        {
            path: "/hiscox",
            name: "Hiscox",
            component: Hiscox
        }
    ]
};


// routes with general layout layout
export const generalRoutes = [
    homeRoutes,
    privateRoutes
];

